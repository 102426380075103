import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";

import { useAuth } from "../modules/auth";
import VehiclePage from "../modules/apps/vehicle/VehiclePage";
import DriverPage from "../modules/apps/driver/DriverPage";

const PrivateRoutes = () => {
  const DispatchPage = lazy(
    () => import("../modules/apps/dispatch/DispatchPage")
  );

  const { currentUser } = useAuth();

  var too = `/dashboard`;

  return (
    <Suspense fallback={<SuspendedView />}>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path="auth/*" element={<Navigate to={too} />} />

          {/* Pages */}
          <Route path="dashboard" element={<DashboardWrapper />} />

          <Route path="builder" element={<BuilderPageWrapper />} />
          <Route path="menu-test" element={<MenuTestPage />} />

          {/* Lazy Modules */}

          <Route
            path="dispatch/*"
            element={
              <SuspendedView>
                <DispatchPage />
              </SuspendedView>
            }
          />
           <Route
            path="vehicle/*"
            element={
              <SuspendedView>
                <VehiclePage />
              </SuspendedView>
            }
          />
          <Route
            path="driver/*"
            element={
              <SuspendedView>
                <DriverPage />
              </SuspendedView>
            }
          />
          {/* Page Not Found */}
          <Route path="*" element={<Navigate to="/error/404" />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

const SuspendedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
