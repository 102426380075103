/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from 'react-router-dom'
import {IconUserModel} from '../../../../profile/ProfileModels'
import {UsersList} from '../../../../profile/components/UsersList'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import Avatar from 'react-avatar'
type Props = {
  icon: string
  catId: string
  title: string
  description: string
  date: string
  files: string
}

const ProjectCard: FC<Props> = ({icon, catId, title, description, date, files}) => {
  const {id} = useParams()
  //var linkAdd = '/project/view/' + id + '/addFile/' + catId
  var linkAdd = '/project/view/' + id + '/listFile/' + catId
  return (
    <Link to={linkAdd} className='card border border-2 border-gray-300 border-hover'>
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px  '>
            <Avatar
              name={icon}
              maxInitials={2}
              round
              color='#607458'
              size='50pt'
              fgColor='#1b1b29'
            ></Avatar>
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-bolder text-dark'>{title}</div>

        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</p>
        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{files} Files</p>

        <div className='d-flex flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>{date}</div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export {ProjectCard}
