import React, { useContext, useEffect, useState } from "react";
import { KTCard, isNotEmpty, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { createDriver } from "../driver-list/core/_requests";

export interface IProfileDetails {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  driverPhoto: string;
  gender: string;
  address_one: string;
  address_two: string;
  postalCode: string;
  city: string;
 license_num: string;
  license_exp_date: string;
  license_frontPic: string;
  license_backPic: string;
  currentLat: string;
  currentLng: string;
  id_type: string;
  id_num: string;
  nationality: string;
  accountNumber: string;
  bankname: string;
  bank_branch: string;
  bank_ifsc: string;
}

export let initialValues: IProfileDetails = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  driverPhoto: "",
  gender: "",
  address_one: "",
  address_two: "",
  postalCode: "",
  city: "",
  license_num: "",
  license_exp_date: "",
  license_frontPic: "",
  license_backPic: "",
  currentLat: "",
  currentLng: "",
  id_type: "",
  id_num: "",
  nationality: "",
  bankname: "",
  bank_branch: "",
  accountNumber:"",
  bank_ifsc: "",
};
const roleDetailsSchema = Yup.object().shape({
   
  firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().required('Email is required').email('Email is invalid'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    phoneNumber: Yup.string().required('Phone num is required'),
    driverPhoto: Yup.string().optional(),
    gender: Yup.string().required('Gender is required'),
    address_one: Yup.string().required('Address line one is required'),
    address_two: Yup.string().optional(),
    city: Yup.string().required('City is required'),
    postalCode: Yup.string().required('Postal code is required'),
    license_num: Yup.string().required('License num is required'),
    license_exp_date: Yup.string().optional(),
    license_num_frontPic: Yup.string().optional(),
    license_num_backPic: Yup.string().optional(),
    id_type: Yup.string().required('ID type is required'),
    id_num: Yup.string().required('ID num is required'),
    nationality: Yup.string().required('Nationality is required'),
    accountNumber: Yup.string().optional(),
    bankname: Yup.string().optional(),
    bank_branch: Yup.string().optional(),
    bank_ifsc: Yup.string().optional(),
});

const RoleDetails: React.FC = () => {
  const { showAlert } = useContext(AlertContext);

  const [data, setData] = useState<IProfileDetails>(initialValues);
  const navigate = useNavigate();
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate);
    setData(updatedData);
  };
  const { currentUser } = useAuth();
  console.log("currentUser", currentUser);
  const [loading, setLoading] = useState(false);
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const updatedData = Object.assign(data, values);
      
      console.log("updatedData", updatedData);
      createDriver(updatedData).then((res) => {
        initialValues = {
          firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
  driverPhoto: "",
  gender: "",
  address_one: "",
  address_two: "",
  postalCode: "",
  city: "",
  license_num: "",
  license_exp_date: "",
  license_frontPic: "",
  license_backPic: "",
  currentLat: "",
  currentLng: "",
  id_type: "",
  id_num: "",
  nationality: "",
  bankname: "",
  bank_branch: "",
  accountNumber:"",
  bank_ifsc: "",
        }
        resetForm({
          ...initialValues,
          values: {...initialValues},
        })
        setData(initialValues)
        navigate('/driver/', {replace: true})
        
        setLoading(false)
        showAlert(res?.Message, res?.Code)
      })
    },
    enableReinitialize: true,
  });
  

  console.log("formik", formik);
  
const Cancelclick=()=>{
  navigate('/driver/')
}
  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Add Driver </h3>
        </div>
      </div>

      <div id="kt_account_profile_details">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <KTCard>
          <div className="card-body border-top p-9">
            {/** Company Info */}
            <div className="row mb-6">
              <div className="row">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter firstname"
                    {...formik.getFieldProps("firstName")}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.firstName}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter lastname"
                    {...formik.getFieldProps("lastName")}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
                {/* <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Customer Name
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Country"
                    {...formik.getFieldProps("usersId")}
                    onChange={(e) => customerChange(e)}
                  >
                    <option value="">Select a Customer</option>
                    {customer.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.customerName}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.usersId && formik.errors.usersId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.usersId}
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Password
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter phone number"
                    {...formik.getFieldProps("phoneNumber")}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.phoneNumber}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Driver Photo
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    
                    {...formik.getFieldProps("driverPhoto")}
                  />
                  {formik.touched.driverPhoto && formik.errors.driverPhoto && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.driverPhoto}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Gender
                  </label>
                  <select
                     
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter gender"
                    {...formik.getFieldProps("gender")}
                  > <option>Male</option>
                  <option>Female</option>
                  <option>Transgender</option></select>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.gender}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Address 1
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter address one"
                    {...formik.getFieldProps("address_one")}
                  />
                  {formik.touched.address_one && formik.errors.address_one && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.address_one}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Address 2
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter address two"
                    {...formik.getFieldProps("address_two")}
                  />
                  {formik.touched.address_two && formik.errors.address_two && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.address_two}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Postcode
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter postalcode"
                    {...formik.getFieldProps("postalCode")}
                  />
                  {formik.touched.postalCode && formik.errors.postalCode && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.postalCode}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter cty"
                    {...formik.getFieldProps("city")}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    License Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter license number"
                    {...formik.getFieldProps("license_num")}
                  />
                  {formik.touched.license_num && formik.errors.license_num && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.license_num}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                   License Expiry Date
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter license expiry date"
                    {...formik.getFieldProps("license_exp_date")}
                  />
                  {formik.touched.license_exp_date && formik.errors.license_exp_date && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.license_exp_date}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Front Picture
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                   
                    {...formik.getFieldProps("license_frontPic")}
                  />
                  {formik.touched.license_frontPic && formik.errors.license_frontPic && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.license_frontPic}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Back Picture
                  </label>
                  <input
                    type="file"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    
                    {...formik.getFieldProps("license_backPic")}
                  />
                  {formik.touched.license_backPic && formik.errors.license_backPic && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.license_backPic}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Current Lattitude
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter current lattitude"
                    {...formik.getFieldProps("currentLat")}
                  />
                  {formik.touched.currentLat && formik.errors.currentLat && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.currentLat}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Current Langitude
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter current longitude"
                    {...formik.getFieldProps("currentLng")}
                  />
                  {formik.touched.currentLng && formik.errors.currentLng && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.currentLng}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Id Type
                  </label>
                  <select
                   
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter ID card type"
                    {...formik.getFieldProps("id_type")}
                  ><option>Adhaar card</option>
                  <option>Voter ID</option>
                  <option>Pan Card</option>
                  <option>Ration Card</option></select>
                  {formik.touched.id_type && formik.errors.id_type && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.id_type}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Id Number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter ID card number"
                    {...formik.getFieldProps("id_num")}
                  />
                  {formik.touched.id_num && formik.errors.id_num && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.id_num}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Nationality
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter nationality"
                    {...formik.getFieldProps("nationality")}
                  />
                  {formik.touched.nationality && formik.errors.nationality && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.nationality}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div></KTCard>
          <KTCard>
          <div className="card-body border-top p-9">
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Account number
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter account number"
                    {...formik.getFieldProps("accountNumber")}
                  />
                  {formik.touched.accountNumber && formik.errors.accountNumber && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.accountNumber}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter bank name"
                    {...formik.getFieldProps("bankname")}
                  />
                  {formik.touched.bankname && formik.errors.bankname && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.bankname}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    Bank Branch
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter branch"
                    {...formik.getFieldProps("bank_branch")}
                  />
                  {formik.touched.bank_branch && formik.errors.bank_branch && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.bank_branch}</div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label   fw-bold fs-6">
                    IFSC Code
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter bank IFSC code"
                    {...formik.getFieldProps("bank_ifsc")}
                  />
                  {formik.touched.bank_ifsc && formik.errors.bank_ifsc && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.bank_ifsc}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </KTCard>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button
           onClick={Cancelclick}
              className="btn btn-secondary mx-2"
              
            >
             
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                 Cancel
                  </span>
               
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {!loading && "Submit"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleDetails;
