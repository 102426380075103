import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import "datatables.net";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { KTCardBody, KTSVG, toAbsoluteUrl } from "../../../../../../_metronic/helpers";
import { getDispatch } from "../core/_requests";

function Table() {
  const [data, setData] = useState([]);
  const dataTableRef = useRef<any>(null);

  useEffect(() => {
    // Fetch data
    getDispatch().then((response) => {
      setData(response);

      dataTableRef.current = $("#myTable").DataTable({
        columns: [
          { orderable: false },
          { orderable: true },
          { orderable: false },
          { orderable: true },
          { orderable: true },
          { orderable: true },
          { orderable: false },
        ],
      });
    });

    return () => {
      if (dataTableRef.current) {
        dataTableRef.current.destroy();
      }
    };
  }, []);

  return (
    <KTCardBody className="py-4">
      <div className="table-responsive">
        <table
          id="myTable"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              <th>S.No</th>
              <th>Name</th>
              <th>Image</th>
              <th>ID Number</th>
              <th>Mobile</th>
              <th>Gender</th>
            </tr>
          </thead>
          <tbody className="text-gray-600 fw-bold">
            {data.map((item: any, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{item?.firstName}</td>
                  <td>
                  {item?.driverPhoto ? ( <img
                     src={item?.driverPhoto}
                      alt="Image"
                      width="50"
                      height="50"
                    />):(<img
                      src={toAbsoluteUrl('/assets/no-image.png')}
                       alt="Image"
                       width="50"
                       height="50"
                     />)}
                  </td>
                  <td>{item?.id_num}</td>
                  <td>{item?.phoneNumber}</td>
                  <td>{item?.gender}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  );
}

export default Table;
