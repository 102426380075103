import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ProjectListHeader } from "./components/header/ProjectListHeader";
import Table from "./table/Table";

import { KTCard } from "../../../../../_metronic/helpers";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { useContext } from "react";
import { AlertBox } from "../../../../../_metronic/layout/components/alert-message";

const ProjectList = () => {
  const { itemIdForUpdate } = useListView();
  const { show, message, type } = useContext(AlertContext);
  return (
    <>
      {show && (
        <AlertBox type={type == "1" ? "success" : "error"} message={message} />
      )}
      
        <ProjectListHeader />
        <Table />
       
    </>
  );
};

const ProjectListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ProjectList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
);

export { ProjectListWrapper };
