import axios, {AxiosResponse} from 'axios'
import {getToken} from '../../../modules/auth'
import {RecentProjectsQueryResponse} from './model'

const API_URL = process.env.REACT_APP_API_URLS
const WIDGET_URL = `${API_URL}/dashboard/widgetCount`
const PROJECT_LIST_URL = `${API_URL}/dashboard/projectList`

const widgetCount = async (): Promise<any> => {
  const result = await axios.get(`${WIDGET_URL}`, getToken())
  return result
}

const recentProject = async (): Promise<RecentProjectsQueryResponse> => {
  const result = await axios.get(`${PROJECT_LIST_URL}`, getToken())
  return result
}

export {widgetCount, recentProject}
