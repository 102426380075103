import { Route, Routes, Outlet, Navigate, useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";

import { AlertBox } from "../../../../_metronic/layout/components/alert-message";
import { AlertContext } from "../../../../_metronic/layout/components/alert-context";
import { useContext } from "react";
import { ProjectListWrapper } from "./vehicle-list/ProjectList";
import { AddProject } from "./vehicle-form";
import { EditWrapper } from "./vehicle-form/EditWrapper";
import { ProjectView } from "./vehicle-list/table/ProjectView";
import { ListFile } from "./vehicle-form/ListFile";
import AddFiles from "./vehicle-form/AddFiles";

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Vehicle Management",
    path: "/vehicle",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const VehiclePage = () => {
  const { show, message, type } = useContext(AlertContext);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Vehicle</PageTitle>

              <ProjectListWrapper />
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}> Add Vehicle</PageTitle>
              <AddProject />
            </>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                {" "}
                Edit Project
              </PageTitle>
            </>
          }
        />
        <Route
          path="/view/:id"
          element={
            <>
              <ProjectView />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default VehiclePage;
