import {Suspense, useContext, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {AlertContext} from '../_metronic/layout/components/alert-context'
import AlertProvider from '../_metronic/layout/components/alert-context/alertContextProvider'

const App = () => {
  const {hideAlert} = useContext(AlertContext)
  useEffect(() => {
    hideAlert(false)
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AlertProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </AlertProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
