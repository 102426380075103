import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { getKEY } from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URLS
const API_AUTH_KEY = `${process.env.REACT_APP_AUTH_KEY}`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/login/verifyToken`
export const LOGIN_URL = `${API_URL}admin/signin`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
 
// Server should return AuthModel
export function login(email:any,password:any) {
  
   
 
  const requestData = {
    email: email,
    password: password,
    is_dashboard: true // Note: Convert to boolean, not string
  };
 

  return axios.post(LOGIN_URL, requestData,  getKEY() )
    
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: any) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    token: token,
  })
}
