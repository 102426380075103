import { Route, Routes, Outlet, Navigate, useNavigate } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../_metronic/layout/core";

import { AlertBox } from "../../../../_metronic/layout/components/alert-message";
import { AlertContext } from "../../../../_metronic/layout/components/alert-context";
import { useContext } from "react";
import { ProjectListWrapper } from "./driver-list/ProjectList";
 
 
import { ProjectView } from "./driver-list/table/ProjectView";
import AddDriver from "./driver-form/AddDriver";
 

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Driver  Management",
    path: "/driver",
    isSeparator: false,
    isActive: false,
  },
  {
    title: "",
    path: "",
    isSeparator: true,
    isActive: false,
  },
];

const DriverPage = () => {
  const { show, message, type } = useContext(AlertContext);
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path="/list"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Driver</PageTitle>

              <ProjectListWrapper />
            </>
          }
        />
        <Route
          path="/add"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}> Add Driver</PageTitle>
              <AddDriver />
            </>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>
                {" "}
                Edit Driver
              </PageTitle>
            </>
          }
        />
        <Route
          path="/view/:id"
          element={
            <>
              <ProjectView />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to="list" />} />
    </Routes>
  );
};

export default DriverPage;
