import { useListView } from "../../core/ListViewProvider";
import { ProjectListToolbar } from "./ProjectListToolbar";
import { ProjectListGrouping } from "./ProjectListGrouping";
import { ProjectListSearchComponent } from "./ProjectListSearchComponent";

const ProjectListHeader = () => {
  const { selected } = useListView();
  return (
    <div className="card-header border-0 pt-6">
      {/* begin::Card toolbar */}
      <div className="card-toolbar">
        {/* begin::Group actions */}
        <ProjectListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  );
};

export { ProjectListHeader };
