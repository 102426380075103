/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { SidebarMenuItem } from "./SidebarMenuItem";

import { useAuth } from "../../../../../app/modules/auth";

const SidebarMenuMain = () => {
  const [sidemenu, setsideMenu] = useState<any>([]);
  const { currentUser } = useAuth();
  const rolesId = currentUser?.id;

  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Dashboard
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/art/art002.svg"
        title="Dashboard"
        fontIcon="bi-layers"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Overview
          </span>
        </div>
      </div>
      <SidebarMenuItem
        to="#"
        icon="/media/icons/duotune/general/gen008.svg"
        title="Dispatch"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/driver"
        icon="/media/icons/duotune/general/gen008.svg"
        title="Driver"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="/vehicle"
        icon="/media/icons/duotune/general/gen008.svg"
        title="Vehicles"
        fontIcon="bi-layers"
      />
      <SidebarMenuItem
        to="#"
        icon="/media/icons/duotune/general/gen008.svg"
        title="Assign Vehicle"
        fontIcon="bi-layers"
      />
    </>
  );
};

export { SidebarMenuMain };
