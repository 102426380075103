import { useNavigate } from "react-router-dom";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
 

const ProjectListToolbar = () => {
  const { setItemIdForUpdate } = useListView();
  const navigate = useNavigate();
  const openAddUserModal = () => {
    navigate("/driver/add");
  };

  return (
    <div
      className="d-flex justify-content-end"
      data-kt-user-table-toolbar="base"
    >
      <button
        type="button"
        className="btn btn-primary btn-sm"
        onClick={openAddUserModal}
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr075.svg"
          className="svg-icon-2"
        />
        New Driver
      </button>
    </div>
  );
};

export { ProjectListToolbar };
