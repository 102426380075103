import { ProjectListLoading } from "../components/loading/ProjectListLoading";
import { ProjectListPagination } from "../components/pagination/ProjectListPagination";
import { KTCardBody, KTSVG } from "../../../../../../_metronic/helpers";
import { ProjectCard } from "./ProjectCard";
import { useQueryResponseLoading } from "../core/QueryResponseProvider";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
// import { getCategoryDropdown, getProjectById } from "../core/_requests";

const ProjectView = () => {
  const navigate = useNavigate();
  function goBack() {
    navigate(-1);
  }
  const isLoading = useQueryResponseLoading();
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = useParams();
  const [data, setData] = useState<any>(null);
  const [DataCat, setDataCat] = useState<any>([]);
  useEffect(() => {
    // getProjectById(id).then((res) => {
    //   setData(res.Data);
    // });
  }, []);

  useEffect(() => {
    // getCategoryDropdown(id, searchTerm).then((res) => {
    //   setDataCat(res.Data);
    // });
  }, []);

  const fetchdata = (e: any) => {
    var values = e.target.value;
    console.log(values);
    setSearchTerm(values);
    // getCategoryDropdown(id, values).then((res) => {
    //   setDataCat(res.Data);
    // });
  };
  //console.log('viewbrea', data)
  return (
    <>
      <button
        onClick={goBack}
        style={{ marginBottom: "15px" }}
        className="btn btn-sm btn-info px-6 align-self-center text-nowrap"
      >
        <i className="bi bi-reply fs-4 me-2"></i> Go Back{" "}
      </button>
      <div className="card-header  row  ">
        <div className="border-0 col-xl-5 mb-5 ">
          <h3 className="card-title align-items-start flex-column mb-4">
            <span className="card-label fw-bold fs-3 mb-1">
              Project Overview
            </span>
          </h3>{" "}
          <Link to="/project/list">
            <span className="text-muted mt-1 fw-semibold fs-4">Project</span>
          </Link>
          <span className="text-muted mt-1 fw-semibold fs-4"> - </span>
          <span className="  mt-1 fw-semibold fs-4">{data?.name}</span>
        </div>
        <div className="row mb-5  col-xl-7 col-12 col-sm-12 col-md-12 px-0"></div>
      </div>
      <div className="d-flex align-items-center position-relative my-1">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-6"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid w-250px ps-14"
          placeholder="Search Category"
          value={searchTerm}
          onChange={(e) => fetchdata(e)}
        />
      </div>
      <KTCardBody className="py-4">
        <div className="row g-6 g-xl-9 mb-10">
          {DataCat.map((item: any, i: any) => {
            var dates = new Date(item?.createdAt);
            var datesField = new Date(dates); // console.log(cvfdates); console.log(cvtdates);
            var formattedDate = datesField.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric",
            });

            return (
              <div className="col-md-4 col-xl-3" key={i}>
                <ProjectCard
                  icon={item?.name}
                  title={item?.name}
                  description={item?.description}
                  date={formattedDate}
                  catId={item?.id}
                  files={item?.fileCount}
                />
              </div>
            );
          })}
        </div>
        <ProjectListPagination />
        {isLoading && <ProjectListLoading />}
      </KTCardBody>
    </>
  );
};
const users9 = [
  { name: "Meloday Macy", avatar: "/media/avatars/300-2.jpg" },
  { name: "Rabbin Watterman", initials: "S", color: "danger" },
];
export { ProjectView };
