import axios, {AxiosResponse} from 'axios'
import { getKEY } from '../../../../auth'
 

const API_URL = process.env.REACT_APP_API_URLS
 const DisURL=`${API_URL}vehicle`

 const getVehicle=()=>{
  return axios.get(`${DisURL}/getall`,  getKEY() ) .then((response: any) => {
   // console.log("---------",response.data);
    return response.data})
 }
 
export {
  getVehicle,
   
}
