import React, { useContext, useEffect, useState } from "react";
import { isNotEmpty, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import { AlertContext } from "../../../../../_metronic/layout/components/alert-context";
import { useQueryResponse } from "../vehicle-list/core/QueryResponseProvider";

export interface IProfileDetails {
  name: string;
  description: string;
  usersId: string;
  createdBy: number | undefined;
  projectstatusId: string;
  managerId: string;
}

export let initialValues: IProfileDetails = {
  name: "",
  description: "",
  usersId: "",
  createdBy: 0,
  projectstatusId: "1",
  managerId: "",
};
const roleDetailsSchema = Yup.object().shape({
  name: Yup.string().required("Project name is required"),
  description: Yup.string().required("Project Description is required"),
  usersId: Yup.string().required("Customer Name is required"),
  projectstatusId: Yup.string().required("Project Status is required"),
  managerId: Yup.string().required("Project Manager is required"),
});

const RoleDetails: React.FC = () => {
  const { showAlert } = useContext(AlertContext);
  const { refetch } = useQueryResponse();
  const [data, setData] = useState<IProfileDetails>(initialValues);
  const navigate = useNavigate();
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate);
    setData(updatedData);
  };
  const { currentUser } = useAuth();
  console.log("currentUser", currentUser);
  const [loading, setLoading] = useState(false);
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: roleDetailsSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const updatedData = Object.assign(data, values);
      updatedData.createdBy = currentUser?.id;
      console.log("updatedData", updatedData);
      // createProject(updatedData).then((res) => {
      //   initialValues = {
      //     name: '',
      //     description: '',
      //     usersId: '',
      //     createdBy: 0,
      //     projectstatusId: '1',
      //     managerId: '',
      //   }
      //   resetForm({
      //     ...initialValues,
      //     values: {...initialValues},
      //   })
      //   setData(initialValues)
      //   navigate('/project/list', {replace: true})
      //   refetch()
      //   setLoading(false)
      //   showAlert(res?.Message, res?.Code)
      // })
    },
    enableReinitialize: true,
  });
  const [customer, setcustomer] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [pmlist, setpmlist] = useState<any>([]);

  console.log("formik", formik);
  function customerChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    throw new Error("Function not implemented.");
  }

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0"
        aria-expanded="true"
        aria-controls="kt_account_profile_details"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Add Project </h3>
        </div>
      </div>

      <div id="kt_account_profile_details">
        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="card-body border-top p-9">
            {/** Company Info */}
            <div className="row mb-6">
              <div className="row">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Project Name
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter Project Name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.name}</div>
                    </div>
                  )}
                </div>

                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Customer Name
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Country"
                    {...formik.getFieldProps("usersId")}
                    onChange={(e) => customerChange(e)}
                  >
                    <option value="">Select a Customer</option>
                    {customer.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.customerName}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.usersId && formik.errors.usersId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.usersId}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <div className="row ">
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Project Manager
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Country"
                    {...formik.getFieldProps("managerId")}
                  >
                    <option value="">Select a Manager</option>
                    {pmlist.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.managerId && formik.errors.managerId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.managerId}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Project Status
                  </label>
                  <select
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Country"
                    {...formik.getFieldProps("projectstatusId")}
                  >
                    <option value="">Select a Status</option>
                    {status.map((item: any, i: any) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.projectstatusId &&
                    formik.errors.projectstatusId && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.projectstatusId}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/** Description Info */}
            <div className="row mb-6">
              <div className="row">
                <div className="col-lg-12 fv-row">
                  <label className="col-form-label required fw-bold fs-6">
                    Project Description
                  </label>
                  <textarea
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter Project Description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.description}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
            >
              {!loading && "Save Changes"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleDetails;
